<template lang="pug">
b-modal(v-model='isModalActive' has-modal-card trap-focus aria-role='dialog' :can-cancel='["x"]' aria-modal)
  modal-card(v-if='contractSelected' :title='`Crear ${contractSelected.name}`' width='500px')
    contract-form(:contract-selected='contractSelected' @saved='saved')
</template>

<script>
import ContractForm from '@/components/contracts/ContractForm'

export default {
  components: { ContractForm },
  props: {
    value: { type: Boolean },
    contractSelected: { type: Object }
  },
  data () {
    return {
      isModalActive: false
    }
  },
  created () {
    this.isModalActive = this.value
  },
  watch: {
    value () {
      this.isModalActive = this.value
    },
    isModalActive () {
      this.$emit('input', this.isModalActive)
    }
  },
  methods: {
    saved ($event) {
      this.$emit('saved', $event)
    }
  }
}
</script>
