<template lang="pug">
  app-form(@submit.prevent='submit' :is-saving='saving')
    field(label='Distribuidor' field='distributor' v-if='distributors.length')
      app-select(id='distributor' :items='distributors' v-model.number='contract.distributorId' :disabled='!!contract.id')
    field(label='Cliente' field='client')
      app-select(id='client' :items='clients' v-model.number='contract.clientId' :disabled='!!contract.id')
    field(label='Tipo' field='typeOfCharge')
      app-select(id='typeOfCharge' :enum='contractTypeEnum' :enum-names='contractTypeDescriptions' v-model.number='contract.typeOfCharge' :disabled='!!contract.id' required)
    field(label='Frec. de cobro' field='chargeFrequency')
      app-select(id='chargeFrequency' :enum='chargeFrequencyTypeEnum' :enum-names='chargeFrequencyTypeDescriptions' v-model.number='contract.chargeFrequency' :disabled='!!contract.id' required)
    field(:label='labelPrice' field='price')
      input#price.input(type='number' step='0.01' placeholder='Precio' min='0' required v-model.number='contract.price')
    field(label='Frecuencia (mes)' field='frequency')
      input#frequency.input(type='number' step='1' placeholder='Valor en meses' min='0' required v-model.number='contract.frequency')
    field(label='Código' field='code')
      input#code.input(type='text' placeholder='Código del contrato' maxlength='50' required v-model='contract.code' :disabled='!!contract.id')
    field(label='Nombre' field='name')
      input#name.input(type='text' placeholder='Nombre del contrato' maxlength='50' required v-model='contract.name')
    field(label='Saldo a favor' field='positiveBalance')
      input#positiveBalance.input(type='number' step='0.01' placeholder='Saldo a favor' min='0' required v-model.number='contract.positiveBalance')
    field(label='Inicio' field='startDate')
      app-date(id='startDate' required v-model='contract.startDate' :disabled='!!contract.id')
    field(label='Fin (opcional)' field='endDate')
      app-date(id='endDate' v-model='contract.endDate')
</template>

<script>
import { contractTypeDescriptions, contractTypeEnum, chargeFrequencyTypeEnum, chargeFrequencyTypeDescriptions } from '@/constants/enums'
import { mapState, mapActions } from 'vuex'
import contractsService from '@/services/contracts.service'
import distributorsService from '@/services/distributors.service'
import toastService from '@/services/toast.service'

export default {
  props: {
    contractSelected: { type: Object }
  },
  data () {
    return {
      contract: {},
      contractTypeEnum,
      contractTypeDescriptions,
      chargeFrequencyTypeEnum,
      chargeFrequencyTypeDescriptions,
      saving: false,
      distributors: []
    }
  },
  created () {
    if (this.$parent.setTitle) {
      this.$parent.setTitle('Crear contrato')
    }
    this.contract = this.contractSelected ? { ...this.contractSelected } : {}
    this.getClients()
    this.getDistributors()
  },
  computed: {
    ...mapState({
      clientsLoading: state => state.clients.loading,
      error: state => state.clients.error,
      clients: state => state.clients.list
    }),
    labelPrice () {
      switch (this.contract.chargeFrequency) {
        case chargeFrequencyTypeEnum.Daily: return 'Precio por día'
        case chargeFrequencyTypeEnum.Montly: return 'Precio por mes'
        default: return 'Precio'
      }
    }
  },
  methods: {
    ...mapActions({
      getClients: 'clients/getClients',
      getDevices: 'devices/getDevices'
    }),
    async getDistributors () {
      const result = await distributorsService.getAll()
      if (result) {
        this.distributors = result
      }
    },
    async submit () {
      this.saving = true
      const result = await contractsService.save(this.contract)
      if (result) {
        toastService.show('Contrato guardado')
        this.$parent.close()
        this.$emit('saved')
      }
      this.saving = false
    }
  }
}
</script>
