<template lang="pug">
  .container
    page-title(title='Contratos')
      button.button.is-primary(@click='create') Nuevo contrato
    .card
      .card-content
        b-table(
          :data='contracts'
          :loading='isLoading'
          narrowed
          hoverable
          mobile-cards
          striped
          searchable
        )
          b-table-column(field='code' label='Código' sortable searchable v-slot='props')
            router-link(:to='{ name: "contract", params: { id: props.row.id  } }') {{ props.row.code }}
          b-table-column(field='name' label='Nombre' sortable searchable v-slot='props') {{ props.row.name }}
          b-table-column(field='distributorName' label='Distribuidor' sortable searchable v-slot='props') {{ props.row.distributorName }}
          b-table-column(field='clientName' label='Cliente' sortable searchable v-slot='props') {{ props.row.clientName }}
          b-table-column(field='positiveBalance' label='A favor' sortable searchable numeric v-slot='props') {{ props.row.positiveBalance | currency }}
          b-table-column(field='typeOfCharge' label='Tipo' sortable searchable v-slot='props') {{ contractTypeDescriptions[props.row.typeOfCharge] }}
          b-table-column(field='price' label='Precio' sortable searchable numeric v-slot='props') {{ props.row.price | currency }}
          b-table-column(field='frequency' label='Frecuencia (Meses)' sortable searchable numeric v-slot='props') {{ props.row.frequency }}
          b-table-column(field='startDate' label='Inicio' sortable searchable v-slot='props') {{ props.row.startDate | date }}
          b-table-column(field='endDate' label='Fin' sortable searchable v-slot='props') {{ props.row.endDate | date }}
          b-table-column(field='lastRunDate' label='Ultimo contrato' sortable searchable v-slot='props') {{ props.row.lastRunDate | date }}
          b-table-column(field='actions' v-slot='props')
            div.norwap
              a(@click='editContract(props.row)')
                icon(icon='edit')
              a.ml-2(@click='deleteContract(props.row)')
                icon(icon='trash')
          template(slot='empty')
            .no-data-found
    contract-form-modal(v-model='contractFormModalActive' :contract-selected='contractSelected' @saved='getContracts')
</template>

<script>
import format from 'date-fns/format'
import contractsService from '@/services/contracts.service'
import ContractFormModal from '@/components/contracts/ContractFormModal'
import { contractTypeDescriptions } from '@/constants/enums'
import toastService from '@/services/toast.service'

export default {
  components: { ContractFormModal },
  data () {
    return {
      contracts: [],
      isLoading: false,
      contractTypeDescriptions,
      // modal props
      contractSelected: null,
      contractFormModalActive: false
    }
  },
  created () {
    this.getContracts()
  },
  methods: {
    async getContracts () {
      this.isLoading = true
      const result = await contractsService.getAll()
      if (result) {
        this.contracts = result
      }
      this.isLoading = false
    },
    create () {
      this.contractSelected = {
        code: `C${format(new Date(), 'yyMMddHHmmss')}`,
        name: `C${format(new Date(), 'yyMMddHHmmss')}`,
        positiveBalance: 0,
        frequency: 1
      }
      this.contractFormModalActive = true
    },
    editContract (contract) {
      this.contractSelected = contract
      this.contractFormModalActive = true
    },
    async deleteContract (contract) {
      const confirmed = await toastService.confirmWarning('Sólo elimine un contrato si nunca se utilizó realmente. En caso contrario debe agregar una fecha fin. ¿Desea eliminar este contrato?')
      if (confirmed) {
        const result = await contractsService.delete(contract)
        if (result) {
          this.contracts = this.contracts.filter(c => c.id !== contract.id)
          toastService.show('Contrato eliminado')
        }
      }
    }
  }
}
</script>
